
$(document).ready(function () {
  function UpdateCaptionTop(){
    if ($(window).width() > 992) {
      $( ".text-block figure" ).each(function( index ) {
        let imageHeight = $(this).children('img').outerHeight();
        $(this).children('figcaption').addClass('absoluteCaption').css('margin-top' , imageHeight + 20);
      });
    }
    else {
      $( ".text-block figure" ).each(function( index ) {
        $(this).children('figcaption').removeClass('absoluteCaption').css('margin-top' , '');
      });
    }
  }

  // Update figcaption top position based on image height
  $(window).on("load", function() {
    UpdateCaptionTop();
  });

  // Update caption top position on window resize
  $(window).resize(function(){
    UpdateCaptionTop();
  });

});
